$(function() {

    document.addEventListener('scroll', function() {
        let featureModelBlocks = document.querySelectorAll('.model__feature-list');
        featureModelBlocks.forEach(function (featureModelBlock) {
            var rect = featureModelBlock.getBoundingClientRect();
            if (rect.bottom > 0 && rect.bottom < window.innerHeight) {
                featureModelBlock.classList.add('show');
            } else {
                featureModelBlock.classList.remove('show');
            }
        });
        
    });

})
