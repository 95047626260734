$(function() {
    let setHoveredInsurance = function (insurance) {

        if ($(insurance).closest('.insurance').hasClass('catalog_row')) return;

        let height = insurance.offsetHeight;
        $(insurance).css('height', height + 'px');
        $(insurance).addClass('insurance_hovered');

        if (window.matchMedia("(min-width: 1024px)").matches) {
            let $cardWrap = $(insurance).find('.insurance__item-wrap');
            let $hiddenBlock = $(insurance).find('.insurance__content');

            if ($cardWrap.length && $hiddenBlock.length) {
                $cardWrap.css('height', height + $hiddenBlock[0].offsetHeight + 'px');
            }
        }
    };

    if (window.matchMedia("(min-width: 1024px)").matches) {

        $('.js-insurance').on('mouseenter', function () {
            setHoveredInsurance(this);
        })

        $('.js-insurance').on('mouseleave', function () {
            $(this).css('height', '');
            $(this).find('.insurance__item-wrap').css('height', '');
            $(this).removeClass('insurance_hovered');
        })

    } else {
        
        $('.js-insurance').on('click', function () {
            if ($('.js-insurance').hasClass('insurance_hovered')) {
                $(this).css('height', '');
                $(this).find('.insurance__item-wrap').css('height', '');
                $(this).removeClass('insurance_hovered');
            } else {
                setHoveredInsurance(this);
            }
        })
    }
})
