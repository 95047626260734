(function () {

	let htmlEl = document.querySelector('html');
	let headerEl = document.querySelector('.header');
	if (!headerEl) return;

	let burgerBtn = headerEl.querySelector('.js-burger-btn');

	burgerBtn.addEventListener('click', function () {
		headerEl.classList.toggle('header_open');
		htmlEl.classList.toggle('open-menu');
	});

	// ---------------------

	let mobMenuEl = document.querySelector('.header__mob-menu');
	let submenuOpenBtns = mobMenuEl.querySelectorAll('.js-submenu-open');

	submenuOpenBtns.forEach(function (submenuOpenBtn) {

		submenuOpenBtn.addEventListener('click', function () {
			this.parentElement.classList.toggle('header__mob-menu-item-wrap_show-submenu');
			mobMenuEl.classList.toggle('header__mob-menu_show-submenu')
		});

	});

	// ----------------------

	let hoveredMenuItem = headerEl.querySelector('.js-hovered-menu-item');

	if (hoveredMenuItem) {

		hoveredMenuItem.addEventListener('mouseenter', function () {
			headerEl.classList.add('header_dark');
		});

		hoveredMenuItem.addEventListener('mouseleave', function () {
			headerEl.classList.remove('header_dark');
		});

	}

	// ----------------------

	let initHeader = function () {
		if (window.scrollY > 0) {
			headerEl.classList.add('header_down');
		} else {
			headerEl.classList.remove('header_down');
		}
	}

	initHeader();

	window.addEventListener('scroll', initHeader);
	
 }());


	
	
	