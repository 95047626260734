$(function() {	

    $('.news-slider__navigation-total').text(($('.news-slider__item').length + ''));

    $('.js-slider-news').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.news-slider__navigation-current').text((nextSlide + 1 + ''));
    });

    $('.js-slider-news').slick({
        slidesToShow: 3,
        dots: true,
        arrows: false,
        appendDots: '.news-slider__control-block',
        dotsClass: 'news-slider__dot-list',
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                }
            }
          ]
    });    

})