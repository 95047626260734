$(function() {

    $('.js-inner-slider').slick({
        slidesToShow: 1,
        arrows: false,
        asNavFor: '.js-inner-thumbnail',
        vertical: false,
        autoplay: false,
        verticalSwiping: false,
        centerMode: false,
        useTransform: false,
    });

    $('.js-inner-slider').on('init', function(event, slick) {
        console.log("initialised")
        $('.js-inner-slider').slick()
    });

    $('.js-inner-thumbnail').slick({
        // slidesToShow: 6,
        centerMode: true,
        variableWidth: true,
        slidesToShow: 1,
        arrows: false,
        vertical: false,
        focusOnSelect: true,
        verticalSwiping: false,
        autoplay: false,
        centerMode: false,      
        asNavFor: '.js-inner-slider',        
    });

    // -----------------------------------------------------------

    const infoBlock = document.querySelector('.js-info-inner');
    const stickyBlock = document.querySelector('.js-sticky-inner');

    if (infoBlock && stickyBlock) {

        document.addEventListener('scroll', function(event) {
            const rect = infoBlock.getBoundingClientRect();
            if (rect.bottom < 100) {
                stickyBlock.classList.add('card-inner__sticky-block_show'); 
            } else {
                stickyBlock.classList.remove('card-inner__sticky-block_show'); 
            }
        })
        
    }

})
