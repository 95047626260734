$(function() {
	
	let slider = document.getElementById('range-slider');
    let $filters = $('.pick-up form');
	let sliderFrom = $filters.find('.js-slider-from');
	let sliderTo = $filters.find('.js-slider-to');

    if (slider) {
        noUiSlider.create(slider, {
            start: [sliderFrom.val(), sliderTo.val()],
            connect: true,
            range: {
                'min': sliderFrom.data('min'),
                'max': sliderTo.data('max')
            },
            format: wNumb({
                decimals: 0,
                thousand: ' ',
                suffix: ' ₽'
            })
        });

        slider.noUiSlider.on('end', function (values, handle) {
            sliderFrom.val(values[0]);
            sliderTo.val(values[1]);
            if (handle === 0) {
                sliderFrom.change();
            } else {
                sliderTo.change();
            }
        });

        slider.noUiSlider.on('update', function (values, handle) {
            sliderFrom.val(values[0]);
            sliderTo.val(values[1]);
        });
    }
})