$(function() {
    const galleryBig = $('.js-new-gallery-big');
    if (galleryBig.length) {
        const arrows = galleryBig.data("arrows").split(",");

        galleryBig.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: `<button type="button" class="slick-prev"><svg><use xlink:href="${arrows[0]}"></use></svg></button>`,
            nextArrow: `<button type="button" class="slick-next"><svg><use xlink:href="${arrows[1]}"></use></svg></button>`,
            fade: true,
            asNavFor: '.js-new-gallery-small'
        });
    }    

    const gallerySmall = $('.js-new-gallery-small');
    if (gallerySmall.length) {
        gallerySmall.slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            swipe: true,
            asNavFor: '.js-new-gallery-big',
            vertical: true,
            verticalSwiping: true,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        vertical: false,
                        verticalSwiping: false,
                        variableWidth: true,
                    },
                },
            ]
        });
    }
});
