$(function () {

    $('.ecology__count-total span').text(($('.ecology__item').length + '').padStart(2, 0));
  
    $('.js-ecology-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.ecology__count-current').text((nextSlide + 1 + '').padStart(2, 0));
    });
  
    $('.js-ecology-slider').slick({
      slidesToShow: 1,
      arrows: true,
      dots: true,
      centerMode: false,
      infinite: false,
      swipeToSlide: true,
      prevArrow: $('.js-slider-ecology-prev'),
      nextArrow: $('.js-slider-ecology-next'),
      appendDots: '.ecology__dot-list-wrap',      
      dotsClass: 'ecology__dot-list',
    })
  })