$(function() {

    let isRowView = localStorage.getItem('isRowView') !== null ? 
		localStorage.getItem('isRowView').toLowerCase() === "true" : 
		false;

    const rowBtn = document.querySelector('.js-row-view');
    const tileBtn = document.querySelector('.js-tile-view');
    const catalogEl = document.querySelector('.catalog');

    if (catalogEl) {

        if (window.matchMedia("(min-width: 650px)").matches) {

            if (isRowView) {
                rowBtn.classList.add('active');
                tileBtn.classList.remove('active');
                catalogEl.classList.add('catalog_row');
            } 
                
            
            
            if (rowBtn) {
                rowBtn.addEventListener('click', function () {
                    let activeBtn = document.querySelector('.sort__type-btn.active');
                    activeBtn.classList.remove('active');

                    this.classList.add('active');
                    catalogEl.classList.add('catalog_row');

		            localStorage.setItem('isRowView', true);
                });
            }

            if (tileBtn) {
                tileBtn.addEventListener('click', function () {
                    let activeBtn = document.querySelector('.sort__type-btn.active');
                    activeBtn.classList.remove('active');

                    this.classList.add('active');
                    catalogEl.classList.remove('catalog_row');

		            localStorage.setItem('isRowView', false);
                });
            }

            
        } 

        catalogEl.classList.remove('visually-hidden');

        if (window.matchMedia("(max-width: 1023px)").matches) {
            catalogEl.classList.remove('catalog_row');
        }

    }
})