$(function() {    

    $('.js-slider4').on('breakpoint', function(event, slick, direction){
      window.app.initCards();
    });

    $('.js-slider4').on('init', function(event, slick, direction){
      window.app.initCards();
    });
    

    $('.js-slider4').slick({
        slidesToShow: 4,
        dots: true,
        arrows: false,
        swipe: false,
        loop: false,
        responsive: [
          {
            breakpoint: 1350,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
    });



    $('.js-slider1').slick({
      slidesToShow: 1,
      dots: true,
      arrows: false
  });
  
})