$(function() {

    $('.js-slider-model-page').slick({
        slidesToShow: 2,
        dots: false,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none"><path d="m15 20-6-7 6-7" stroke="#02283B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none"><path d="m11 20 6-7-6-7" stroke="#02283B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

})
