$(function() {

	// let isFilterOpened = localStorage.getItem('isFilterOpened') !== null ? 
	// 	localStorage.getItem('isFilterOpened').toLowerCase() === "true" : 
	// 	false;

	let filterFormEl = document.querySelector('.filter__wrap');
	if (!filterFormEl) return;

	// if (isFilterOpened) {
	// 	filterFormEl.classList.add('filter__wrap_all');
	// }

	let showAllBtn = filterFormEl.querySelector('.js-show-btn');
	
	showAllBtn.addEventListener('click', function () {
		filterFormEl.classList.toggle('filter__wrap_all');

		// isFilterOpened = !isFilterOpened;
		// localStorage.setItem('isFilterOpened', isFilterOpened);
	});

})