$(function() {

    let modelEl = document.querySelector('.models');

    if (modelEl) {
        let modelInputEls = modelEl.querySelectorAll('.js-model-input');
        
        modelInputEls.forEach(function (input) {
            input.addEventListener('change', function () {
                this.parentElement.classList.toggle('checked');
            });

            let label = input.parentElement;
            label.addEventListener('mouseenter', function () {
                modelEl.classList.add('models_hovered');
            });

            label.addEventListener('mouseleave', function () {
                modelEl.classList.remove('models_hovered');
            });
        });
    }	

})