$(function() {

    let setHoveredCard = function (card) {
        if ($(card).closest('.catalog').hasClass('catalog_row')) return;

        let height = card.offsetHeight;
        $(card).css('height', height + 'px');
        $(card).addClass('card_hovered');

        if (window.matchMedia("(min-width: 1024px)").matches) {
            let $cardWrap = $(card).find('.card__wrap'); 
            let $hiddenBlock = $(card).find('.card__btn-block');
            
            if ($cardWrap.length && $hiddenBlock.length) {
                $cardWrap.css('height', height + $hiddenBlock[0].offsetHeight + 'px');
            }
        }
    };

    const initCards = function() {
        $('.js-card').on('mouseenter', function () {
            setHoveredCard(this);
        });

        $('.js-card').on('mouseleave', function () {
            $(this).css('height', '');
            $(this).find('.card__wrap').css('height', '');
            $(this).removeClass('card_hovered');
        });
    }

    initCards();

    window.app = window.app || {};
    window.app.initCards = initCards;

    // ---------------------------------------------------------------------

    var initHoveredImage = function (imgEl) {
            

        var setSourcesSrcset = function (imgIndex) {
            var sourceEls = imgEl.parentElement.querySelectorAll('source');
            sourceEls.forEach(function (sourceEl) {
                var urlsString = sourceEl.dataset.images;
                if (!urlsString) return;
                    
                var urls = JSON.parse(urlsString);
                if (!urls.length) return;

                if (urls.length > imgIndex)
                    sourceEl.srcset = urls[imgIndex];
            });
        };

        if (imgEl.classList.contains('initiated')) return;

        var urlsString = imgEl.dataset.images;
        if (!urlsString) return;
            
        var urls = JSON.parse(urlsString);
        if (!urls.length) return;

        var indicatorsContainer = imgEl.parentElement.parentElement.querySelector('.js-indicators');

        if (!indicatorsContainer) return;

        imgEl.classList.add('initiated');
        // debugger;
        imgEl.addEventListener('mousemove', function (evt) {
            // debugger;
            var lineWidth = imgEl.clientWidth / urls.length;
            
            var imgIndex = Math.floor(evt.offsetX / lineWidth);
            if (imgIndex >= urls.length) 
                imgIndex = urls.length - 1;
            if (imgIndex < 0) 
                imgIndex = 0;

            imgEl.src = urls[imgIndex];
            setSourcesSrcset(imgIndex);

            var indicators = indicatorsContainer.querySelectorAll('.card__indicator');
            var activeIndicator = indicatorsContainer.querySelector('.card__indicator.active');

            if (activeIndicator !== indicators[imgIndex]) {
                if (activeIndicator)
                    activeIndicator.classList.remove('active');

                indicators[imgIndex].classList.add('active');
            }
        });

        
        for (var i = 0; i < urls.length; i++) {
            var indicator = document.createElement('span');
            indicator.classList.add('card__indicator');
            if (i === 0) {
                indicator.classList.add('active');
            }
            indicatorsContainer.appendChild(indicator);
        }
    };

    // var imageEls = document.querySelectorAll('.js-hoveredImages');

    // imageEls.forEach(initHoveredImage);

    //window.app.initHoveredImage = initHoveredImage;
    window.app = window.app || {};
    window.app.initHoveredImages = function () {
        if (window.matchMedia("(max-width: 650px)").matches) {
            $('.js-slider-card-img').slick({
                slidesToShow: 1,
                dots: true,
                arrows: false
            });
        } else {

            var imageEls = document.querySelectorAll('.js-hoveredImages');

            imageEls.forEach(initHoveredImage);

        }

    };


    window.app.initHoveredImages();

    // --------------------------------------------------

    // if (window.matchMedia("(max-width: 650px)").matches) {
    //     $('.js-slider-card-img').slick({
    //         slidesToShow: 1,
    //         dots: true,
    //         arrows: false
    //     });
    // }

})