$(function() {
	
	let brandsEl = document.querySelector('.brands');

    if (brandsEl) {

        let showMoreBtn = brandsEl.querySelector('.js-show-more-btn');

        if (showMoreBtn) {
            showMoreBtn.addEventListener('click', function () {
                brandsEl.classList.toggle('brands_show-all');
            });
        }

    }	

})