$(function() {

    const initSlider = function () {
        $('.js-slider-selections:not(.slick-initialized)').slick({
            slidesToShow: 1,
            arrows: true,    
            prevArrow: '<button type="button" class="slick-prev"><svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.1852 34.1234C26.4852 34.4091 26.4967 34.8838 26.2111 35.1837C25.9254 35.4837 25.4507 35.4953 25.1507 35.2096L26.1852 34.1234ZM18.1507 28.5429C17.8508 28.2573 17.8392 27.7825 18.1249 27.4826C18.4105 27.1826 18.8853 27.1711 19.1852 27.4567L18.1507 28.5429ZM19.1852 28.5429C18.8853 28.8286 18.4105 28.817 18.1249 28.5171C17.8392 28.2171 17.8508 27.7424 18.1507 27.4567L19.1852 28.5429ZM25.1507 20.7901C25.4507 20.5044 25.9254 20.516 26.2111 20.8159C26.4967 21.1159 26.4852 21.5906 26.1852 21.8763L25.1507 20.7901ZM18.668 28.7498C18.2538 28.7498 17.918 28.4141 17.918 27.9998C17.918 27.5856 18.2538 27.2498 18.668 27.2498V28.7498ZM37.3346 27.2498C37.7488 27.2498 38.0846 27.5856 38.0846 27.9998C38.0846 28.4141 37.7488 28.7498 37.3346 28.7498V27.2498ZM25.1507 35.2096L18.1507 28.5429L19.1852 27.4567L26.1852 34.1234L25.1507 35.2096ZM18.1507 27.4567L25.1507 20.7901L26.1852 21.8763L19.1852 28.5429L18.1507 27.4567ZM18.668 27.2498L37.3346 27.2498V28.7498L18.668 28.7498V27.2498Z" fill="white"/><rect x="-0.5" y="0.5" width="55" height="55" rx="27.5" transform="matrix(-1 0 0 1 55 0)" stroke="white"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.8148 34.1234C29.5148 34.4091 29.5033 34.8838 29.7889 35.1837C30.0746 35.4837 30.5493 35.4953 30.8493 35.2096L29.8148 34.1234ZM37.8493 28.5429C38.1492 28.2573 38.1608 27.7825 37.8751 27.4826C37.5895 27.1826 37.1147 27.1711 36.8148 27.4567L37.8493 28.5429ZM36.8148 28.5429C37.1147 28.8286 37.5895 28.817 37.8751 28.5171C38.1608 28.2171 38.1492 27.7424 37.8493 27.4567L36.8148 28.5429ZM30.8493 20.7901C30.5493 20.5044 30.0746 20.516 29.7889 20.8159C29.5033 21.1159 29.5148 21.5906 29.8148 21.8763L30.8493 20.7901ZM37.332 28.7498C37.7462 28.7498 38.082 28.4141 38.082 27.9998C38.082 27.5856 37.7462 27.2498 37.332 27.2498V28.7498ZM18.6654 27.2498C18.2512 27.2498 17.9154 27.5856 17.9154 27.9998C17.9154 28.4141 18.2512 28.7498 18.6654 28.7498V27.2498ZM30.8493 35.2096L37.8493 28.5429L36.8148 27.4567L29.8148 34.1234L30.8493 35.2096ZM37.8493 27.4567L30.8493 20.7901L29.8148 21.8763L36.8148 28.5429L37.8493 27.4567ZM37.332 27.2498L18.6654 27.2498V28.7498L37.332 28.7498V27.2498Z" fill="white"/><rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="white"/></svg></button>'
        });
    };

    $('.js-slider-selections').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        
        let featureBlock = slick.$slides[currentSlide].querySelector('.selections__feature-list');
        featureBlock.classList.remove('show');    

        featureBlock = slick.$slides[nextSlide].querySelector('.selections__feature-list');
        featureBlock.classList.add('show');    

    });

    const tabBlock = document.querySelector('.selections__container.tab');

    if (tabBlock) {
        tabBlock.addEventListener('tabchanged', function (evt) {
            initSlider();

            $('.js-slider-selections').slick('setPosition');

            $('.selections__feature-list.show').removeClass('show');
            $(evt.detail.tabcontent).find('.slick-current .selections__feature-list').addClass('show');
        });
    }

    initSlider();

    // ----------------------------------------------------------------------------


    document.addEventListener('scroll', function() {
        let featureBlocks = document.querySelectorAll('.slick-current .selections__feature-list');
        featureBlocks.forEach(featureBlock => {
            let rect = featureBlock.getBoundingClientRect();
            if (rect.bottom > 0 && rect.bottom < window.innerHeight) {
                featureBlock.classList.add('show');
            } else {
                featureBlock.classList.remove('show');
            }
        });
        
    });

})
