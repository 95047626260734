$(function() {	
    
    if (IMask) {
        const phones = document.querySelectorAll('[type="tel"]');
        phones.forEach(phone => {
            IMask(phone, {
                mask: '+{7} 000 000-00-00'
            })
        })            

        const emails = document.querySelectorAll('[type="email"]');
        emails.forEach(mail => {
            IMask(mail, {
            mask: /^\S*@?\S*$/,
            });
        });

        const numberMaskEls = document.querySelectorAll('.js-number-mask');

        numberMaskEls.forEach(function (numberMaskEl) {

            IMask(
                numberMaskEl,
                {
                    mask: Number,
                    min: -1000000000000000,
                    max: 1000000000000000,
                    thousandsSeparator: ' '
                }
            )

        });        

    } else {
        console.error('IMask: не подключен модуль');
    }

    // -----------------------------------

    $('.js-input').on('input', function () {
        if ($(this).val()) {
            $(this).parent().parent().addClass('has-value');
        } else {
            $(this).parent().parent().removeClass('has-value');
        }
    })
    

    let $select = $(".js-select2");
    $select.on('select2:select', function () {
        // debugger;
        if ($(this).select2('data').length && $(this).select2('data')[0].id !== 'all') {
            $(this).closest('.filter__item').addClass('has-value');
        } else {
            $(this).closest('.filter__item').removeClass('has-value');
        }
    });
    

    let $multipleSelect = $(".js-select2-multiple2");
    $multipleSelect.on('change', function () {
        // debugger;
        if ($(this).select2('data').length) {
            $(this).closest('.filter__item').addClass('has-value');
        } else {
            $(this).closest('.filter__item').removeClass('has-value');
        }
        
    });
   
})