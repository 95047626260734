$(function() {

    let selects = document.querySelectorAll(".js-select2");

    selects.forEach((selectEl) => {
        var $select = $(selectEl);
        $select.select2({
            minimumResultsForSearch: -1,
            placeholder: {
                id: '-1', // the value of the option
                text: selectEl.dataset.placeholder
            },
        })
    });      
    
})

jQuery(function($) {
	$.fn.select2.amd.require([
    'select2/selection/single',
    'select2/selection/placeholder',
    'select2/selection/allowClear',
    'select2/dropdown',
    'select2/dropdown/search',
    'select2/dropdown/attachBody',
    'select2/utils'
  ], function (SingleSelection, Placeholder, AllowClear, Dropdown, DropdownSearch, AttachBody, Utils) {

	var SelectionAdapter = Utils.Decorate(
      SingleSelection,
      Placeholder
    );
    
    SelectionAdapter = Utils.Decorate(
      SelectionAdapter,
      AllowClear
    );
          
    var DropdownAdapter = Utils.Decorate(
      Utils.Decorate(
        Dropdown,
        DropdownSearch
      ),
      AttachBody
    );
    
    var base_element = $('.js-select2-multiple2')
    $(base_element).select2({
      theme: 'multiple',
      placeholder: 'Выберите модель',
      selectionAdapter: SelectionAdapter,
      dropdownAdapter: DropdownAdapter,
      allowClear: true,
      templateResult: function (data) {

        if (!data.id) { return data.text; }

        var $res = $('<div></div>');

        $res.text(data.text);
        $res.addClass('wrap');

        return $res;
      },
      templateSelection: function (data) {
      	if (!data.id) { return data.text; }

        var selected = [];

        if ($(data.element.parentElement).hasClass("select2-hidden-accessible")) {
          // Select2 has been initialized
          selected = $(data.element.parentElement).select2('data') || [];
        } else {
          selected = $(data.element.parentElement).find(':selected').toArray();
        }
        
        return selected.map(function (dataItem) { 
          return dataItem.text;
        }).join(', ');
      },
    })
  
  });
  
});